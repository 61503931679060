/********************
 * CHARTS
 ********************/

/* easy pie chart */
.easy-pie-chart {
	position: relative;
	margin: 0 auto;
	margin-bottom: 15px;
	text-align: center;

	.percent {
		@include inline-block;

		width: 100%;
		position: absolute;
		line-height: 130px;
		z-index: 2;
		font-size: 28px;
		text-align: center;
	}
}

/* flot chart */
.flot-donut-label {
	padding: 5px 10px;
	background-color: rgba(0, 0, 0, .7);
	color: #fff;
}

/* sparkline and flot tooltip */
.jqstooltip {
	@include border-radius(2px !important);
}

.flotTip {
	background-color: #f4f7f9 !important;
	border-color: darken(#f4f7f9, 10%) !important;

	.jqsfield {
		color: $font-color;
	}
}

.flotTip-blue {
	background-color: rgba(#4286c9, .8) !important;
	border-color: #3c7fc1 !important;
	color: #fff;

	.jqsfield {
		color: #fff;
	}
}

.flotTip-black {
	background-color: rgba(#181818, .8) !important;
	border: none !important;
	color: #fff;

	.jqsfield {
		color: #fff;
	}
}

.legend table td {
	padding: 5px 0;

	&.legendLabel {
		padding-left: 8px;
	}
}

/* sparkline chart */
.sparkline-stat-medium {
	padding: 8px 0;
	margin-bottom: 30px;

	strong {
		display: block;
		font-size: 1.5em;
		font-weight: 300;
	}
}

/* sparkline bar chart */
.sparkbar-stat-item {
	.mini-bar-chart {
		position: relative;
		top: -10px;
		left: 8px;
	}
}

/* sparkline big chart */
.sparkline-stat-big {
	position: relative;
	padding: 5px 20px 0 20px;
	color: #506167;

	.summary {
		position: absolute;
	}

	.big-number {
		float: left;
		margin-right: 10px;
		font-size: 4em;
		font-weight: 300;
		line-height: .7;
	}
}

/* charts at dashboard page */
#traffic-sources-chart {
	width: 100%;
	height: 300px;
}

#sales-performance-chart {
	width: 100%;
	height: 270px;
}

/* gauge chart */
.activity-gauge {
	> div {
		width: 230px;
		height: 115px;
		text-align: center;
	}

	tspan {
		font-weight: 300;
	}

}

