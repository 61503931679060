.formLink {
  color: #00aaff;
  cursor: pointer;
}

.subMenu {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}
.subMenu > span:not(:last-child) {
  margin-right: 2rem;
}
.subMenu svg {
  margin-right: 1rem;
}
