/********************
 * HELPERS
 ********************/


.bg-success {
	background-color: $color-success;
}

.bg-warning {
	background-color: $color-warning;
}

.bg-danger {
	background-color: $color-danger;
}

.bg-info {
	background-color: $color-info;
}

.no-padding {
	padding: 0 !important;
}

.no-padding-top {
	padding-top: 0 !important;
}

.no-padding-right {
	padding-right: 0 !important;
}

.no-padding-bottom {
	padding-bottom: 0 !important;
}

.no-padding-left {
	padding-left: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.no-margin-top {
	margin-top: 0 !important;
}

.no-margin-right {
	margin-right: 0 !important;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.no-margin-left {
	margin-left: 0 !important;
}

.margin-top-30 {
	margin-top: 30px !important;
}

.margin-top-15 {
	margin-top: 15px !important;
}


.margin-bottom-15 {
	margin-bottom: 15px !important;
}

.margin-bottom-30 {
	margin-bottom: 30px !important;
}

.padding-top-30 {
	padding-top: 30px !important;
}

.padding-bottom-30 {
	padding-bottom: 30px !important;
}

.margin-top-50 {
	margin-top: 50px !important;
}

.margin-bottom-50 {
	margin-bottom: 50px !important;
}

.padding-top-50 {
	padding-top: 50px !important;
}

.padding-bottom-50 {
	padding-bottom: 50px !important;
}

.padding-25 {
	padding: 25px !important;
}

