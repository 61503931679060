/***************************************
 * STYLES SPECIFIC TO ANGULAR VERSION
 ***************************************/

.sidebar {
	.nav {
		li {
			> a[data-toggle] {
				.icon-submenu {
					-moz-transform: rotate(0deg);
					-ms-transform: rotate(0deg);
					-webkit-transform: rotate(0deg);
					transform: rotate(0deg);
				}
			}
		}

		li.open {
			> a[data-toggle] {
				&,
				&:active {
					color: $color-accent;
					border-left-color: $color-accent;
					background-color: transparent;
				}

				.icon-submenu {
					-moz-transform: rotate(-90deg);
					-ms-transform: rotate(-90deg);
					-webkit-transform: rotate(-90deg);
					transform: rotate(-90deg);
				}
			}
		}

		li.panel {
			@include box-shadow(none);
		}
	}
}

.right-sidebar {
	padding-top: 55px;
}

/* ngx-bootstrap dropdown */
[dropdown] {
	position: relative;
}

/* datepicker */
datepicker,
[datepicker] {
	table > tbody > tr > td {
		.btn {
			margin: 0;
			border: none;

			&.active span {
				color: #fff !important;
			}
		}
	}
}

// importing skins
@import "skins/navbar4";
@import "skins/sidebar-nav-darkgray";


