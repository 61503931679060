/********************
 * PAGE ERRORS
 ********************/

.page-error {
	text-align: center;

	.vertical-align-middle {
		padding: 30px;
	}

	h1 {
		margin-top: 0;
	}

	.title {
		@include inline-block;

		text-align: center;
	}

	.number {
		font-size: 4em;
		
		&.left {
			@include inline-block; 
		
			float: left;
			text-align: right;
			margin-right: 5px;
		}
	}

	.text {
		@include inline-block;

		float: left;
		text-align: left;
		font-size: 1.5em;
		line-height: 1.2;
		position: relative;
		top: 12px;

		@include max-screen($break-small) {
			margin-bottom: 40px;
		}
	}

	.number.left,
	.text {
		@include max-screen($break-small) {
			display: block;
			width: 100%;
			float: none;
			text-align: center;
		}

		@include max-screen($break-xsmall) {
			font-size: 1.4em;
		}
	}

	.searchbox {
		margin: 50px auto;
		width: 40%;

		@include max-screen($break-xsmall) {
			width: 100%;

			input[type="search"],
			.input-group,
			.input-group-btn,
			.btn {
				display: block;
				width: 100%;
				float: none;
			}
		}

		@include max-screen($break-small) {
			width: 80%;
		}
	}
}


