.userMenu {
  position: relative;
  display: block;
  transition: .3s ease-in-out;
  color: #fff;
  font-weight: 300;
  padding: 0 20px;
  line-height: 55px;
  cursor: pointer;
}

.userMenu.open, .userMenu:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, .15);
}
