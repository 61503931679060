.Loader {
    align-items: center;
    display: flex;
    justify-content: center;
}

.Loader .label {
    font-size: 2.5rem;
    margin-left: 1rem;
}
