/***********************
 * BOOTSTRAP ELEMENTS
 ***********************/

/* bootstrap progress bar */
.progress {
	@include border-radius(2px);
	@include box-shadow(none);

	background-color: #f3f5f9;

	&.wide {
		width: 60px;
	}

	.progress-bar {
		@include transition(width 3s ease);
		@include box-shadow(none);

		position: relative;
	}

	&.progress-lg {
		height: 28px;
	}

	&.progress-sm {
		height: 12px;
	}

	&.progress-xs {
		height: 5px;
	}

	&.progress-sm, 
	&.progress-xs {
		.progress-bar {
			text-indent: -9999px;
		}
	}
}

.progress-bar {
	background-color: $color-accent;
}

.progress-bar-success {
	background-color: $color-success;
}

.progress-bar-warning {
	background-color: $color-warning;
}

.progress-bar-danger {
	background-color: $color-danger;
}

.progress-bar-info {
	background-color: $color-info;
}

@each $color-name, $color-value in $custom-colors {
	@include progress-transparent-variant($color-name, $color-value);
}

.progress-right-percentage {
	@include clearfix;

	.progress {
		width: calc(100% - 40px);
		float: left;
		margin-bottom: 0;
	}

	.percentage {
		width: 40px;
		float: right;
		position: relative;
		top: -9px;
		text-align: right;
	}
}

.progress-state-name {
	display: block;
	margin-top: 9px;
	margin-bottom: 6px;
	font-size: 13px;
}

.progress + .square-legend {
	margin-top: 8px;
}

.popover {
	@include border-radius(3px);
	@include box-shadow(3px 3px 3px -1px rgba(161, 172, 181, 0.3));

	padding: 0;
	border: 1px solid $border-panel-color;
	font-family: inherit;
	font-size: 14px;

	&.bottom > .arrow {
		border-bottom-color: $border-panel-color;
	}

	&.top > .arrow {
		border-top-color: $border-panel-color;
	}

	&.right > .arrow {
		border-right-color: $border-panel-color;
	}

	&.left > .arrow {
		border-left-color: $border-panel-color;
	}
}

.popover-title {
		@include border-top-radius(3px);

		background-color: #f8f9fa;
	}

.badge {
	font-weight: normal;
}

.badge-primary {
	background-color: $color-accent;
}

.badge-success {
	background-color: $color-success;
}

.badge-warning {
	background-color: $color-warning;
}


.badge-danger {
	background-color: $color-danger;
}

.badge-info {
	background-color: $color-info;
}


/* pagination */
.pagination {
	> li > a, 
	> li > span {
		@include border-radius(3px);

		border: none;
		color: $color-accent;

		&:hover,
		&:focus {
			color: $color-accent;
			background-color: #f6f8f9;
			border-color: #f6f8f9;
		}
	}

	> .disabled {
		> a, > span {
			&,
			&:focus,
			&:hover {
				color: $muted-color;
			}
		}
	}
}

/* tooltip */
.tooltip-inner {
	background-color: #5a5a5a;
}

.tooltip {
	&.in {
		@include opacity(1);
	}

	&.left .tooltip-arrow {
		border-left-color: #5a5a5a;
	}

	&.right .tooltip-arrow {
		border-right-color: #5a5a5a;
	}

	&.top .tooltip-arrow {
		border-top-color: #5a5a5a;
	}

	&.bottom .tooltip-arrow {
		border-bottom-color: #5a5a5a;
	}
}
