.ErrorPage {
  align-items: center;
  display: flex;
  left: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 120px;
}
.ErrorPage :global(.alert) {
  width: 80%;
}
